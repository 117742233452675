var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "box-shadow": "4px 4px 10px 0px rgba(0, 0, 0, 0.15)",
      "border-radius": "12px",
      "padding": "20px",
      "display": "flex",
      "align-items": "center",
      "justify-content": "space-between",
      "gap": "20px",
      "cursor": _vm.hasOnClick ? 'pointer' : 'default',
      "background-color": _vm.styles.backgroundColor
    },
    on: {
      "click": _vm.onClick
    }
  }, [_c('c-box', {
    attrs: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "4px"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "14px-2",
      "size-desktop": "16px"
    }
  }, [_vm._v(" " + _vm._s(_vm.description) + " ")])], 1), _c('c-box', {
    attrs: {
      "min-height": "25px",
      "min-width": "25px",
      "height": "25px",
      "width": "25px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.styles.iconUrl,
      "height": "100%",
      "width": "100%",
      "fill": _vm.styles.iconColor
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }