
<template>
  <c-box
    box-shadow="4px 4px 10px 0px rgba(0, 0, 0, 0.15)"
    border-radius="12px"
    padding="20px"
    display="flex"
    align-items="center"
    justify-content="space-between"
    gap="20px"
    :cursor="hasOnClick ? 'pointer' : 'default'"
    :background-color="styles.backgroundColor"
    @click="onClick"
  >
    <c-box
      display="flex"
      flex-direction="column"
      gap="4px"
    >
      <BaseText
        size-mobile="18px"
        size-desktop="20px"
      >
        {{ title }}
      </BaseText>
      <BaseText
        size-mobile="14px-2"
        size-desktop="16px"
      >
        {{ description }}
      </BaseText>
    </c-box>
    <c-box
      min-height="25px"
      min-width="25px"
      height="25px"
      width="25px"
    >
      <!--        :src="require('@/assets/icons/icon-circle-warn.svg')"-->
      <inline-svg
        :src="styles.iconUrl"
        height="100%"
        width="100%"
        :fill="styles.iconColor"
      />
    </c-box>
  </c-box>
</template>

<script>
import BaseText from '@/components/elements/base-text.vue'

export default {
  name: 'SimpleCard2',
  components: {
    BaseText,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      default: 'success',
      validator(value) {
        return ['success', 'warning'].includes(value)
      },
    },
  },
  computed: {
    hasOnClick() {
      return !!this?.$listeners?.['on-click']
    },
    styles() {
      switch (this.status) {
        case 'success':
          return {
            iconUrl: require('@/assets/icons/icon-circle-check.svg'),
            iconColor: '#008C81',
            backgroundColor: '#FFF',
          }
        case 'warning':
          return {
            iconUrl: require('@/assets/icons/icon-circle-warn.svg'),
            iconColor: '#D32737',
            backgroundColor: '#FDEDCB',
          }
        default:
          return {
            iconUrl: require('@/assets/icons/icon-circle-check.svg'),
            iconColor: '#008C81',
            backgroundColor: '#FFF',
          }
      }
    },
  },
  methods: {
    onClick() {
      this.$emit('on-click')

      // const data = {
      //   program_detail: {
      //     id: 'xxxx',
      //     name: 'Body Goals',
      //     start_date: '10 Juli 2022',
      //     end_date: '10 Agustus 2022',
      //     duration: '30',
      //     counter_days: '5', // belom
      //   },
      //   user_detail: {
      //     id: 'xxxx',
      //     name: 'Nichole Brokeman',
      //     photo: 'https://ap-south-1.linodeobjects.com/dietela-storage/users/profile/9a3d4638-ca3d-4b86-aaa7-e08488bae862/2022-5/Screenshot2022-05-31094146.png', // belom
      //   },
      //   nutritionist_detail: {
      //     id: 'xxxx',
      //     name: 'Chandry Thirtasary S.Gz., RD',
      //     photo: 'https://ap-south-1.linodeobjects.com/dietela-storage/users/profile/9a3d4638-ca3d-4b86-aaa7-e08488bae862/2022-5/Screenshot2022-05-31094146.png', // belom
      //     service_hours: '09.00 - 17.00',
      //     specializations: ['Nama spesialiasi 1', 'Nama spesialisasi 2', 'Nama spesialisasi 3', 'Nama spesialisasi 4'],
      //     educations: ['S1 & Sekolah Profesi Universitas Brawijaya'],
      //     rating: '4.5/5',
      //     no_str: '1409621161129320',
      //     mastered_problem: ['Manajemen berat badan', 'Metabolic diet (DM, jantung, hipertensi, ginjal, asam urat)'],
      //     client_age_handled: ['12 – 17 tahun (Remaja)', '18 – 60 tahun (Dewasa)'],
      //     work_experience: ['Praktik di FUllerton Health'],
      //     languanges: ['Bahasa Indonesia', 'Bahasa Inggris'],
      //   },
      //   meal_plan_schedule: [{
      //     id: 'xxxx',
      //     title: 'Profile Gizi',
      //     date: '12 November 2022',
      //     status: 'warning',
      //   }, {
      //     id: 'xxxx',
      //     title: 'Rencana Makan',
      //     date: '12 November 2022',
      //     status: 'success',
      //   }],
      //   adime_notes_and_indicator_progress_and_tracker_schedule: [{
      //     id: 'xxxx',
      //     title: 'ADIME Notes',
      //     date: '12 November 2022',
      //     status: 'warning',
      //   }, {
      //     id: 'xxxx',
      //     title: 'Indikator Pemantauan',
      //     date: '12 November 2022',
      //     status: 'success',
      //   }],
      //   follow_up_notes_schedule: [{
      //     id: 'xxxx',
      //     title: 'Follow Up Notes',
      //     date: '12 November 2022',
      //     status: 'warning',
      //   }, {
      //     id: 'xxxx',
      //     title: 'Follow Up Notes',
      //     date: '12 November 2022',
      //     status: 'success',
      //   }],
      //   progress_and_tracker_feedback_schedule: [{
      //     id: 'xxxx',
      //     title: 'Progress & Tracker Feedback',
      //     date: '12 November 2022',
      //     status: 'warning',
      //   }, {
      //     id: 'xxxx',
      //     title: 'Progress & Tracker Feedback',
      //     date: '12 November 2022',
      //     status: 'success',
      //   }],
      // }
    },
  },
}
</script>
