<template>
  <c-box
    width="100%"
    background-color="white"
    :border-radius="['8px', '20px']"
    border="1px solid #f2f2f2"
    box-shadow="0px 5px 30px 0px #0000000D"
    display="flex"
    flex-direction="column"
  >
    <c-flex
      width="100%"
      :padding="['15px 20px 8px 20px', '30px 20px 13px 20px']"
      :gap="['10px', '16px']"
    >
      <c-box
        :min-width="['50px', '96px']"
        :width="['50px', '96px']"
        :height="['50px', '96px']"
        overflow="hidden"
        border-radius="100%"
        background-color="lightGray.900"
      >
        <c-image
          object-fit="cover"
          width="100%"
          height="100%"
          :src="getPhotoNutritionist(photo)"
          :alt="name"
        />
      </c-box>
      <c-box
        width="100%"
        display="flex"
        flex-direction="column"
        justify-content="center"
        gap="12px"
      >
        <c-heading
          as="h3"
          :font-size="['14px', '18px']"
          :line-height="['21px', '27px']"
          font-weight="700"
          color="black.900"
          font-family="Roboto"
        >
          {{ name }}
          <!--          {{ getFullnameAndTitle }}-->
        </c-heading>
        <BaseText
          size-mobile="12px"
          size-desktop="14px-2"
        >
          Jam Layanan: {{ serviceHours }}
        </BaseText>
      </c-box>
      <c-box
        v-show="remainingQuota !== null"
        display="flex"
        align-items="center"
      >
        <LabelQuotaNutritionist :remaining-quota="remainingQuota" />
      </c-box>
    </c-flex>
    <BaseDivider margin="0" />
    <c-box
      display="flex"
      gap="8px"
      padding="20px"
    >
      <c-box
        display="flex"
        flex-direction="column"
        :gap="['5px', '16px']"
        margin-bottom="auto"
        width="100%"
      >
        <c-text
          v-if="hasSpecializations"
          font-family="Roboto"
          :font-size="['14px', '16px']"
          color="primary.400"
          class="line-clamp-1"
        >
          {{ getSpecialization }}
        </c-text>
        <c-box>
          <c-text
            v-for="(education, index) in educations"
            :key="'edu' + index"
            font-family="Roboto"
            :font-size="['12px', '14px']"
            color="darkGray.900"
            text-transform="capitalize"
          >
            {{ education }}
            <!--            {{ education.level }} - {{ education.university === "0" ? education.otherUniversity : getUniversityName(education.university) }}-->
          </c-text>
        </c-box>
        <Rating :rating="ratings" />
      </c-box>
      <c-box
        display="flex"
        align-items="center"
      >
        <slot name="button">
          <BaseButton
            v-if="hasOnShowProfile"
            border-radius="1000px"
            width="100%"
            size="large"
            padding="0 50px"
            @click.prevent="onShowProfile"
          >
            Lihat Profil
          </BaseButton>
          <BaseButton
            v-else-if="hasOnChangeNutritionist"
            border-radius="1000px"
            width="100%"
            size="large"
            padding="0 50px"
            :disabled="isDisabledChangeNutritionist"
            @click.prevent="onChangeNutritionist"
          >
            Ganti Ahli Gizi
          </BaseButton>
        </slot>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import Rating from '@/views/client/nutritionists/_widgets/rating.vue'
import generalMixin from '@/utils/general-mixins'
import BaseDivider from '@/components/elements/base-divider.vue'
import BaseButton from '@/components/elements/base-button.vue'
import BaseText from '@/components/elements/base-text.vue'
import LabelQuotaNutritionist from '@/components/elements/label-quota-nutritionist.vue'

export default {
  name: 'CardNutritionist',
  components: {
    LabelQuotaNutritionist,
    BaseText,
    BaseButton,
    BaseDivider,
    Rating,
  },
  mixins: [
    generalMixin,
  ],
  props: {
    photo: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    serviceHours: {
      type: String,
      default: '',
    },
    specializations: {
      type: [String, Array],
      default: '',
    },
    educations: {
      type: Array,
      default: () => [],
    },
    ratings: {
      type: [String, Number],
      default: 0,
    },
    remainingQuota: {
      type: Number,
      default: null,
    },
    isDisabledChangeNutritionist: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasOnShowProfile() {
      return !!this?.$listeners?.['on-show-profile']
    },
    hasOnChangeNutritionist() {
      return !!this?.$listeners?.['on-change-nutritionist']
    },
    hasSpecializations() {
      return this.specializations || this.specializations?.length > 0
    },
    getSpecialization() {
      switch (typeof this.specializations) {
        case 'string':
          return this.specializations
        case 'object':
          if (this.specializations?.length > 0) {
            return this.specializations.join(', ')
          }
          return ''
        default:
          return ''
      }
    },
  },
  methods: {
    onShowProfile() {
      this.$emit('on-show-profile')
    },
    onChangeNutritionist() {
      this.$emit('on-change-nutritionist')
    },
  },
}
</script>
