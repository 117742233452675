var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%",
      "background-color": "white",
      "border-radius": ['8px', '20px'],
      "border": "1px solid #f2f2f2",
      "box-shadow": "0px 5px 30px 0px #0000000D",
      "display": "flex",
      "flex-direction": "column"
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "padding": ['15px 20px 8px 20px', '30px 20px 13px 20px'],
      "gap": ['10px', '16px']
    }
  }, [_c('c-box', {
    attrs: {
      "min-width": ['50px', '96px'],
      "width": ['50px', '96px'],
      "height": ['50px', '96px'],
      "overflow": "hidden",
      "border-radius": "100%",
      "background-color": "lightGray.900"
    }
  }, [_c('c-image', {
    attrs: {
      "object-fit": "cover",
      "width": "100%",
      "height": "100%",
      "src": _vm.getPhotoNutritionist(_vm.photo),
      "alt": _vm.name
    }
  })], 1), _c('c-box', {
    attrs: {
      "width": "100%",
      "display": "flex",
      "flex-direction": "column",
      "justify-content": "center",
      "gap": "12px"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h3",
      "font-size": ['14px', '18px'],
      "line-height": ['21px', '27px'],
      "font-weight": "700",
      "color": "black.900",
      "font-family": "Roboto"
    }
  }, [_vm._v(" " + _vm._s(_vm.name) + " ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "14px-2"
    }
  }, [_vm._v(" Jam Layanan: " + _vm._s(_vm.serviceHours) + " ")])], 1), _c('c-box', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.remainingQuota !== null,
      expression: "remainingQuota !== null"
    }],
    attrs: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('LabelQuotaNutritionist', {
    attrs: {
      "remaining-quota": _vm.remainingQuota
    }
  })], 1)], 1), _c('BaseDivider', {
    attrs: {
      "margin": "0"
    }
  }), _c('c-box', {
    attrs: {
      "display": "flex",
      "gap": "8px",
      "padding": "20px"
    }
  }, [_c('c-box', {
    attrs: {
      "display": "flex",
      "flex-direction": "column",
      "gap": ['5px', '16px'],
      "margin-bottom": "auto",
      "width": "100%"
    }
  }, [_vm.hasSpecializations ? _c('c-text', {
    staticClass: "line-clamp-1",
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "color": "primary.400"
    }
  }, [_vm._v(" " + _vm._s(_vm.getSpecialization) + " ")]) : _vm._e(), _c('c-box', _vm._l(_vm.educations, function (education, index) {
    return _c('c-text', {
      key: 'edu' + index,
      attrs: {
        "font-family": "Roboto",
        "font-size": ['12px', '14px'],
        "color": "darkGray.900",
        "text-transform": "capitalize"
      }
    }, [_vm._v(" " + _vm._s(education) + " ")]);
  }), 1), _c('Rating', {
    attrs: {
      "rating": _vm.ratings
    }
  })], 1), _c('c-box', {
    attrs: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_vm._t("button", function () {
    return [_vm.hasOnShowProfile ? _c('BaseButton', {
      attrs: {
        "border-radius": "1000px",
        "width": "100%",
        "size": "large",
        "padding": "0 50px"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onShowProfile.apply(null, arguments);
        }
      }
    }, [_vm._v(" Lihat Profil ")]) : _vm.hasOnChangeNutritionist ? _c('BaseButton', {
      attrs: {
        "border-radius": "1000px",
        "width": "100%",
        "size": "large",
        "padding": "0 50px",
        "disabled": _vm.isDisabledChangeNutritionist
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onChangeNutritionist.apply(null, arguments);
        }
      }
    }, [_vm._v(" Ganti Ahli Gizi ")]) : _vm._e()];
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }